<template>
  <td class="dual-stack-rule-cell" :data-id="dataItem.Id">
    <Badge
      v-for="(rule, idx) in dataItem.DualStackRule"
      :key="idx"
      :type="'normal'"
      class="mr-2 my-1"
    >
      {{ rule }}
    </Badge>
  </td>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Badge from '@/components/Badge/Badge.vue'

export default defineComponent({
  components: {
    Badge
  },
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String
  },
  setup() {
    return {}
  }
})
</script>

<style scoped></style>
