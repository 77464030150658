import { ref, computed, markRaw } from 'vue'
import { orderBy } from '@progress/kendo-data-query'

// Cells
import PolicySetting from '../components/Grid/Cell/NetworkPage/PolicySetting.vue'
import PolicySettingHeader from '../components/Grid/Cell/NetworkPage/PolicySettingHeader.vue'
import Range from '../components/Grid/Cell/NetworkPage/Range.vue'
import Operation from '../components/Grid/Cell/NetworkPage/Operation.vue'
import OperationHeader from '../components/Grid/Cell/NetworkPage/OperationHeader.vue'
import DualStackRule from '../components/Grid/Cell/NetworkPage/DualStackRule.vue'
import DHCPServer from '../components/Grid/Cell/NetworkPage/DHCPServer.vue'
import Status from '../components/Grid/Cell/HostPage/Status.vue'
import StatusHeader from '../components/Grid/Cell/HostPage/StatusHeader.vue'

// 網段列表中使用 Kendo Grid
const useGridInNetwork = () => {
  // grid 資料
  const dataItems = ref([])
  // 分頁用
  const pageable = {
    buttonCount: 1,
    pageSizes: [16, 32, 128, 255],
    type: 'input',
    info: false
  }
  const skip = ref(0)
  const take = ref(16)
  // 排序用
  const sort = ref([])
  // 選擇
  const selectedID = ref(0)
  const onRowClick = e => {
    console.log('onRowClick Id: ' + e.dataItem.Id)
    selectedID.value = e.dataItem.Id
  }

  // Header 欄位
  const columns = ref([
    {
      field: 'NetworkName',
      title: '網段名稱',
      width: '180px'
    },
    {
      field: 'PolicySetting',
      title: '政策設定',
      width: '120px',
      cell: markRaw(PolicySetting),
      headerCell: markRaw(PolicySettingHeader)
    },
    {
      field: 'Operation',
      title: '操作',
      width: '117px',
      cell: markRaw(Operation),
      headerCell: markRaw(OperationHeader)
    },
    {
      field: 'Status',
      title: '網段狀態',
      width: '117px',
      cell: markRaw(Status),
      headerCell: markRaw(StatusHeader)
    },
    {
      field: 'SiteName',
      title: '所屬區域',
      width: '126px'
    },
    {
      field: 'GroupName',
      title: '所屬群組',
      width: '90px'
    },
    {
      field: 'Range',
      title: '管理範圍',
      width: '200px',
      cell: markRaw(Range)
    },
    {
      field: 'DHCPServer',
      title: 'DHCP Server',
      width: '190px',
      cell: markRaw(DHCPServer)
    },
    {
      field: 'DualStackRule',
      title: 'Daul Stack 規則',
      width: '140px',
      cell: markRaw(DualStackRule)
    },
    {
      field: 'VlanId',
      title: 'VLAN ID',
      width: 'auto'
    }
  ])
  // 手動分頁
  const result = computed(() => {
    const dataItemsWithPage = dataItems.value.slice(
      skip.value,
      take.value + skip.value
    )
    const dataItemsWithPageAndSort = orderBy(dataItemsWithPage, sort.value)
    return dataItemsWithPageAndSort.map(item => ({
      ...item,
      selected: item.Id === selectedID.value
    }))
  })
  // 總資料數
  const total = computed(() => {
    return dataItems.value ? dataItems.value.length : 0
  })

  // 生成假資料
  const createRandomData = count => {
    const networkName = ['Service Department', 'R&D Department']
    const siteName = ['Taipei Office', 'San Jose Office']
    const operation = [
      { isDeletable: true },
      { isDeletable: true },
      { isDeletable: false }
    ]
    const status = [
      [
        { IconCode: 3, Content: 'IP 封鎖(管理者)<br>172.168.2.3' },
        { IconCode: 1, Content: 'MAC 封鎖(管理者)' },
        { IconCode: 0, Content: '禁止變更 IP<br>192.168.87.254' }
      ],
      [
        { IconCode: 3, Content: 'IP 封鎖(管理者)<br>172.168.2.3' },
        { IconCode: 2, Content: '重要 IP<br>192.168.61.171' }
      ],
      [
        { IconCode: 3, Content: 'IP 封鎖(管理者)<br>172.168.2.3' },
        { IconCode: 4, Content: '未授權 MAC 封鎖' }
      ],
      [{ IconCode: 4, Content: '未授權 MAC 封鎖' }],
      [{ IconCode: 5, Content: '未符規白名單設備' }],
      []
    ]
    const dHCPServer = [
      [
        { type: 'primary', text: 'DHCPv6' },
        { type: 'warning', text: 'DHCPv6' }
      ],
      [{ type: 'danger', text: 'DHCPv6' }]
    ]
    // 已開 DHCP 服務
    const isDHCPServiceEnabled = [true, false]
    // 已開啟監看模式
    const isMonitorEnabled = [true, false]

    return Array(count)
      .fill({})
      .map((_, idx) => ({
        Id: idx + 1, // Unique Id for select
        NetworkName:
          networkName[Math.floor(Math.random() * networkName.length)],
        SiteName: siteName[Math.floor(Math.random() * siteName.length)],
        PolicySetting: '',
        Operation: operation[Math.floor(Math.random() * operation.length)],
        Status: status[Math.floor(Math.random() * status.length)],
        GroupName: 'Default',
        Range: ['192.168.16.x', '2001:4420:2222:FF02::'],
        DHCPServer: dHCPServer[Math.floor(Math.random() * dHCPServer.length)],
        DualStackRule: ['對應 IPv4 尾 2 碼'],
        VlanId: 'VLAN ID',
        IsDHCPServiceEnabled:
          isDHCPServiceEnabled[
          Math.floor(Math.random() * isDHCPServiceEnabled.length)
          ],
        IsMonitorEnabled:
          isMonitorEnabled[Math.floor(Math.random() * isMonitorEnabled.length)]
      }))
  }

  const pageChangeHandler = e => {
    skip.value = e.page.skip
    take.value = e.page.take
  }

  // columns 重新排序
  const columnReorder = options => {
    columns.value = options.columns
  }

  // sort 處理
  const sortChangeHandler = e => {
    console.log('sort')
    sort.value = e.sort
  }

  return {
    columns,
    dataItems,
    pageable,
    skip,
    take,
    result,
    total,
    sort,
    createRandomData,
    pageChangeHandler,
    onRowClick,
    columnReorder,
    sortChangeHandler
  }
}

export default useGridInNetwork
