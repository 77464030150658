<template>
  <h2 class="build-env-title">建置環境</h2>

  <Tabs :class="{ 'is-treeview-open': isTreeViewOpen }">
    <Tab pathName="Site" title="區域列表" />
    <Tab pathName="Network" title="網段列表" />
    <Tab pathName="Probe" title="Probe 列表" />
  </Tabs>

  <div
    class="main-list-view network-list-view"
    :class="{ 'is-treeview-open': isTreeViewOpen }"
  >
    <!-- 左側：樹狀圖 -->
    <div class="tree-nav-container">
      <div class="close-btn" @click="isTreeViewOpen = false">
        <SvgIcon icon="arrow-right" />
      </div>
      <h3 class="tree-title">選取查詢範圍</h3>
      <div class="treeview-wrapper">
        <TreeView
          ref="treeViewRef"
          :data-source="localDataSource"
          :data-text-field="['name']"
          :template="template"
          :checkboxes="true"
          :checkboxes-check-children="true"
        ></TreeView>
      </div>
    </div>
    <!-- 右側：列表容器 -->
    <div class="main-list-container">
      <!-- 上：控制列容器 -->
      <ControlWrapper>
        <ControlButton
          iconName="plus"
          text="新增網段"
          @click="toggleNewNetwork"
        />
        <div class="vertical-divider"></div>
        <ControlButton iconName="star" text="所有監看模式網段" />
      </ControlWrapper>
      <!-- 中：篩選列容器 -->
      <FilterWrapper>
        <FilterTextInput
          :placeholder="'查詢 IP / MAC / 主機名稱'"
          v-model.trim="textInput"
          @submitFilter="submitFilter"
        />
        <div
          class="toggle-treeview-btn flex-shrink-0"
          @click="isTreeViewOpen = !isTreeViewOpen"
        >
          <SvgIcon icon="tree" />
        </div>
        <FilterUnitDeletable
          v-for="filterUnit in filterUnitList"
          :filterUnit="filterUnit"
          :key="filterUnit.key"
          @updateFilterUnit="updateFilterUnit"
          @deleteFilterUnit="deleteFilterUnit"
        />
        <FilterUnitAdd
          :keyWithOptions="keyWithOptions"
          @addFilterUnit="addFilterUnit"
        />
        <div class="spacer"></div>
      </FilterWrapper>
      <!-- 下：表格容器 -->
      <GridWrapper>
        <GridPageInfo :skip="skip" :take="take" :total="total" />
        <Grid
          id="kendo-grid"
          :style="{ height: 'calc(100vh - 300px)' }"
          :columns="columns"
          :data-items="result"
          :cell-render="'defaultTemplate'"
          :pageable="pageable"
          :skip="skip"
          :take="take"
          :total="total"
          @pagechange="pageChangeHandler"
          :scrollable="'true'"
          :selected-field="'selected'"
          @rowclick="onRowClick"
          :reorderable="true"
          @columnreorder="columnReorder"
          :sortable="true"
          :sort="sort"
          @sortchange="sortChangeHandler"
          :class="{ 'is-sortable': true }"
        >
          <!-- 預設 cell render template，帶有 ['data-id'] -->
          <template v-slot:defaultTemplate="{ props }">
            <td :class="props.className" :data-id="props.dataItem.Id">
              {{ getNestedValue(props.field, props.dataItem) }}
            </td>
          </template>
        </Grid>
      </GridWrapper>
    </div>
    <!-- 隱藏：Dialog 容器 -->
    <div class="dialog-container">
      <Dialog
        v-if="showNewNetwork"
        :title="'新增網段'"
        :width="930"
        :height="480"
        @close="toggleNewNetwork"
      >
        <NewNetworkForm @close="toggleNewNetwork" />
      </Dialog>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, markRaw, onMounted, ref } from 'vue'
import Tabs from '@/components/Tabs/Tabs.vue'
import Tab from '@/components/Tabs/Tab.vue'
import '@progress/kendo-ui/js/kendo.treeview'
import { TreeView } from '@progress/kendo-treeview-vue-wrapper'
import '@progress/kendo-ui/js/kendo.data'
// import { HierarchicalDataSource } from '@progress/kendo-datasource-vue-wrapper'
import ControlWrapper from '@/components/Control/ControlWrapper.vue'
import ControlButton from '@/components/Control/ControlButton.vue'
import FilterWrapper from '@/components/Filter/FilterWrapper.vue'
import FilterTextInput from '@/components/Filter/FilterTextInput.vue'
import FilterUnitDeletable from '@/components/Filter/FilterUnitDeletable.vue'
import FilterUnitAdd from '@/components/Filter/FilterUnitAdd.vue'
import useFilterUnitList from '@/composable/useFilterUnitList'
import GridWrapper from '@/components/Grid/GridWrapper.vue'
import { Grid } from '@progress/kendo-vue-grid'
import useGridInNetwork from '@/composable/useGridInNetwork'
import GridPageInfo from '@/components/Grid/GridPageInfo.vue'

import '@progress/kendo-ui/js/kendo.dialog'
import { Dialog } from '@progress/kendo-vue-dialogs'
import useToggle from '@/composable/useToggle'
import NewNetworkForm from '@/components/Form/NetworkPage/NewNetworkForm.vue'

import {
  treeViewData,
  treeViewSchema,
  keyWithOptions,
  initialFilterUnitList
} from '@/mock/data/network'

export default defineComponent({
  components: {
    Tabs,
    Tab,
    TreeView,
    ControlWrapper,
    ControlButton,
    FilterWrapper,
    FilterTextInput,
    FilterUnitDeletable,
    FilterUnitAdd,
    GridWrapper,
    Grid,
    GridPageInfo,
    Dialog,
    NewNetworkForm
  },
  setup() {
    // TreeView 相關
    // 注意！目前先使用 localDataSource 作法，待後端 API 完成請使用 remote HierarchicalDataSource

    /* template 目的為依據 item.icons 欄位渲染 icon，例如：<span class="tree-icon tree-i-site"></span>
     * .tree-i-* 須額外定義，檔案位置： '@/assets/styles/global/modify/kendo/_treeview.scss'
     */
    const template =
      '# for (i = 0; i < item.icons.length; i++ ) { #' +
      '<span class="tree-icon ' +
      '#: item.icons[i] #' +
      '"></span>' +
      '# } #' +
      '#: item.name #' +
      '<span class="child-count">' +
      '#: Number(item.count).toLocaleString() #' + // 1000 -> "1,000"
      '</span>'

    const localDataSource = markRaw(
      // eslint-disable-next-line
        new kendo.data.HierarchicalDataSource({
        data: treeViewData,
        schema: treeViewSchema
      })
    )

    const isTreeViewOpen = ref(false)

    // treeview template refs
    const treeViewRef = ref(null)

    // 篩選相關
    // 文字輸入
    const textInput = ref('')

    // 自訂篩選相關
    const {
      filterUnitList,
      updateFilterUnit,
      deleteFilterUnit,
      addFilterUnit
    } = useFilterUnitList(initialFilterUnitList)

    // 提交篩選
    const submitFilter = () => {
      // FIXME: 這裡提供了文字輸入、勾選、自訂篩選組的值，可以做後續組裡
      console.log('textInput: ' + textInput.value)
      console.log('filterUnitList: ' + filterUnitList.value)
    }

    // Grid 相關
    const {
      columns,
      dataItems,
      pageable,
      skip,
      take,
      result,
      total,
      sort,
      createRandomData,
      pageChangeHandler,
      onRowClick,
      columnReorder,
      sortChangeHandler
    } = useGridInNetwork()

    onMounted(() => {
      dataItems.value = createRandomData(255) as never[]
    })

    // cell default template 取得此欄位 fieldName 對應的值
    const getNestedValue = (fieldName, dataItem) => {
      const path = fieldName.split('.')
      let data = dataItem
      path.forEach(p => {
        data = data ? data[p] : undefined
      })

      return data
    }

    // Dialog 相關
    const { show: showNewNetwork, toggle: toggleNewNetwork } = useToggle()

    return {
      // TreeView 相關
      template,
      localDataSource,
      isTreeViewOpen,
      treeViewRef,
      // 篩選相關：文字輸入
      textInput,
      submitFilter,
      // 篩選相關：自訂篩選
      keyWithOptions,
      filterUnitList,
      updateFilterUnit,
      deleteFilterUnit,
      addFilterUnit,
      // Grid 相關
      columns,
      dataItems,
      pageable,
      skip,
      take,
      result,
      total,
      sort,
      createRandomData,
      pageChangeHandler,
      onRowClick,
      columnReorder,
      sortChangeHandler,
      getNestedValue,
      // Dialog 相關：新增網段
      showNewNetwork,
      toggleNewNetwork
    }
  }
})
</script>

<style scoped></style>
