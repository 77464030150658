
import { defineComponent, markRaw, onMounted, ref } from 'vue'
import Tabs from '@/components/Tabs/Tabs.vue'
import Tab from '@/components/Tabs/Tab.vue'
import '@progress/kendo-ui/js/kendo.treeview'
import { TreeView } from '@progress/kendo-treeview-vue-wrapper'
import '@progress/kendo-ui/js/kendo.data'
// import { HierarchicalDataSource } from '@progress/kendo-datasource-vue-wrapper'
import ControlWrapper from '@/components/Control/ControlWrapper.vue'
import ControlButton from '@/components/Control/ControlButton.vue'
import FilterWrapper from '@/components/Filter/FilterWrapper.vue'
import FilterTextInput from '@/components/Filter/FilterTextInput.vue'
import FilterUnitDeletable from '@/components/Filter/FilterUnitDeletable.vue'
import FilterUnitAdd from '@/components/Filter/FilterUnitAdd.vue'
import useFilterUnitList from '@/composable/useFilterUnitList'
import GridWrapper from '@/components/Grid/GridWrapper.vue'
import { Grid } from '@progress/kendo-vue-grid'
import useGridInNetwork from '@/composable/useGridInNetwork'
import GridPageInfo from '@/components/Grid/GridPageInfo.vue'

import '@progress/kendo-ui/js/kendo.dialog'
import { Dialog } from '@progress/kendo-vue-dialogs'
import useToggle from '@/composable/useToggle'
import NewNetworkForm from '@/components/Form/NetworkPage/NewNetworkForm.vue'

import {
  treeViewData,
  treeViewSchema,
  keyWithOptions,
  initialFilterUnitList
} from '@/mock/data/network'

export default defineComponent({
  components: {
    Tabs,
    Tab,
    TreeView,
    ControlWrapper,
    ControlButton,
    FilterWrapper,
    FilterTextInput,
    FilterUnitDeletable,
    FilterUnitAdd,
    GridWrapper,
    Grid,
    GridPageInfo,
    Dialog,
    NewNetworkForm
  },
  setup() {
    // TreeView 相關
    // 注意！目前先使用 localDataSource 作法，待後端 API 完成請使用 remote HierarchicalDataSource

    /* template 目的為依據 item.icons 欄位渲染 icon，例如：<span class="tree-icon tree-i-site"></span>
     * .tree-i-* 須額外定義，檔案位置： '@/assets/styles/global/modify/kendo/_treeview.scss'
     */
    const template =
      '# for (i = 0; i < item.icons.length; i++ ) { #' +
      '<span class="tree-icon ' +
      '#: item.icons[i] #' +
      '"></span>' +
      '# } #' +
      '#: item.name #' +
      '<span class="child-count">' +
      '#: Number(item.count).toLocaleString() #' + // 1000 -> "1,000"
      '</span>'

    const localDataSource = markRaw(
      // eslint-disable-next-line
        new kendo.data.HierarchicalDataSource({
        data: treeViewData,
        schema: treeViewSchema
      })
    )

    const isTreeViewOpen = ref(false)

    // treeview template refs
    const treeViewRef = ref(null)

    // 篩選相關
    // 文字輸入
    const textInput = ref('')

    // 自訂篩選相關
    const {
      filterUnitList,
      updateFilterUnit,
      deleteFilterUnit,
      addFilterUnit
    } = useFilterUnitList(initialFilterUnitList)

    // 提交篩選
    const submitFilter = () => {
      // FIXME: 這裡提供了文字輸入、勾選、自訂篩選組的值，可以做後續組裡
      console.log('textInput: ' + textInput.value)
      console.log('filterUnitList: ' + filterUnitList.value)
    }

    // Grid 相關
    const {
      columns,
      dataItems,
      pageable,
      skip,
      take,
      result,
      total,
      sort,
      createRandomData,
      pageChangeHandler,
      onRowClick,
      columnReorder,
      sortChangeHandler
    } = useGridInNetwork()

    onMounted(() => {
      dataItems.value = createRandomData(255) as never[]
    })

    // cell default template 取得此欄位 fieldName 對應的值
    const getNestedValue = (fieldName, dataItem) => {
      const path = fieldName.split('.')
      let data = dataItem
      path.forEach(p => {
        data = data ? data[p] : undefined
      })

      return data
    }

    // Dialog 相關
    const { show: showNewNetwork, toggle: toggleNewNetwork } = useToggle()

    return {
      // TreeView 相關
      template,
      localDataSource,
      isTreeViewOpen,
      treeViewRef,
      // 篩選相關：文字輸入
      textInput,
      submitFilter,
      // 篩選相關：自訂篩選
      keyWithOptions,
      filterUnitList,
      updateFilterUnit,
      deleteFilterUnit,
      addFilterUnit,
      // Grid 相關
      columns,
      dataItems,
      pageable,
      skip,
      take,
      result,
      total,
      sort,
      createRandomData,
      pageChangeHandler,
      onRowClick,
      columnReorder,
      sortChangeHandler,
      getNestedValue,
      // Dialog 相關：新增網段
      showNewNetwork,
      toggleNewNetwork
    }
  }
})
